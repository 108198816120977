<template>
  <div>
    <template v-for="(item, index) in itemList">
      <div
        class="goods"
        v-if="true"
        :key="`main_${index}`"
        :style="
          index + 1 >= itemList.length ? 'border-bottom:1px solid #ccc' : ''
        "
      >
        <div class="info">
          <div class="left">본상품</div>
          <div class="right">주문 {{ item.ea }}개</div>
        </div>
        <div class="img">
          <img :src="item.image" @error="replaceImg" :alt="item.goods_name" />
        </div>
        <div class="txt">
          <h3>{{ item.goods_name }}</h3>
          <!-- <h4>
            {{ won(parseInt(item.sale_price) * parseInt(item.ea)) }}원
            <span
              v-if="
                parseInt(item.sale_price) * parseInt(item.ea) <
                parseInt(item.out_price) * parseInt(item.ea)
              "
            >
              {{ won(parseInt(item.out_price) * parseInt(item.ea)) }}원
            </span>
          </h4> -->
          <h4>
            {{ won(parseInt(item.sale_price)) }}원
            <span v-if="parseInt(item.sale_price) < parseInt(item.out_price)">
              {{ won(parseInt(item.out_price)) }}원
            </span>
          </h4>
          <p v-if="item.title1">{{ item.title1 }} : {{ item.option1 }}</p>
          <p v-if="item.title2">{{ item.title2 }} : {{ item.option2 }}</p>
          <p v-if="item.title3">{{ item.title3 }} : {{ item.option3 }}</p>
          <p v-if="item.title4">{{ item.title4 }} : {{ item.option4 }}</p>
          <p v-if="item.title5">{{ item.title5 }} : {{ item.option5 }}</p>
        </div>
      </div>
      <template v-if="item.suboptions.length > 0">
        <div class="add_goods" v-for="(sub, idx) in item.suboptions" :key="idx">
          <div class="info">
            <div class="left">추가상품</div>
            <div class="right">주문 {{ sub.ea }}개</div>
          </div>
          <div class="txt">
            <h3>{{ sub.title }}({{ sub.suboption }})</h3>
            <!-- <h4>{{ won(parseInt(sub.price) * parseInt(sub.ea)) }}원</h4> -->
            <h4>{{ won(parseInt(sub.price)) }}원</h4>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import _sortBy from "lodash/sortBy";
export default {
  data() {
    return {
      itemListArray: [],
    };
  },
  props: {
    orderData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  computed: {
    itemList() {
      let data = {
        addPrice: 0,
        suboptions: [],
      };
      if (this.orderData.items) {
        this.orderData.items.forEach((item) => {
          item.options.forEach((opt) => {
            if (opt.goods_seq) {
              data = {
                addPrice: 0,
                suboptions: [],
              };
              data.out_price = opt.out_price;
              data.sale_price = opt.sale_price;
              data.image = item.image;
              data.goods_name = item.goods_name;
              data.goods_seq = item.goods_seq;
              data.item_option_seq = opt.item_option_seq;
              data.item_seq = opt.item_seq;
              data.title1 = opt.title1;
              data.title2 = opt.title2;
              data.title3 = opt.title3;
              data.title4 = opt.title4;
              data.title5 = opt.title5;
              data.option1 = opt.option1;
              data.option2 = opt.option2;
              data.option3 = opt.option3;
              data.option4 = opt.option4;
              data.option5 = opt.option5;
              data.ea = opt.ea ? opt.ea : 0;
              data.rt_ea = opt.refund_ea ? parseInt(opt.refund_ea) : 0;

              if (opt.suboptions) {
                Object.keys(opt.suboptions).forEach((key) => {
                  const sub = opt.suboptions[key];
                  console.log(sub);
                  let title = sub.title;
                  let suboption = sub.suboption;
                  let price = parseInt(sub.price);
                  data.suboptions.push({
                    title,
                    suboption,
                    ea: sub.ea ? sub.ea : 0,
                    price,
                    item_suboption_seq: sub.item_suboption_seq,
                    rt_ea: sub.refund_ea ? parseInt(sub.refund_ea) : 0,
                  });
                });
                // console.log(data);
                data.suboptions = _sortBy(
                  data.suboptions,
                  "item_suboption_seq"
                );
              }
              this.itemListArray.push(data);
            }
          });
        });
      }
      return this.itemListArray;
    },
  },
  methods: {
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
    won(str) {
      return this.$won(str);
    },
  },
};
</script>

<style lang="scss">
h4 {
  padding: 0 0 5px 0;
}
</style>
