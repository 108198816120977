var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rn_my_wrap"},[_c('div',{staticClass:"rn_my_tit_2"},[_vm._v(" 영수증 조회"),_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.closeModal()}}},[_c('img',{attrs:{"src":require("@/assets/images/sub/back_ico.png"),"alt":""}})])],1),_c('div',{staticClass:"rn_my_empty_30"}),_c('div',{staticClass:"rn_my_box_37"},[_c('h3',[_vm._v("영수증 조회/발급")]),_c('div',{staticClass:"order_info"},[_c('p',[_vm._v("주문번호")]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.orderData.orders.order_seq))])]),_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.open.apply(null, arguments)}}},[_vm._v("결제 영수증")])],1),_c('OrderItemReceipt',{attrs:{"orderData":_vm.orderData}}),_c('div',{staticClass:"del_fee"},[_c('div',{staticClass:"left"},[_vm._v("배송비")]),_c('div',{staticClass:"right"},[_vm._v(" "+_vm._s(_vm.won(_vm.orderData.shipping_tot.total_shipping_cost))+"원 ")])]),(
        _vm.orderData.orders.tax_receipt_possible &&
        _vm.orderData.orders.deposit_yn === 'y' &&
        !_vm.orderData.orders.typereceipt &&
        parseFloat(_vm.orderData.orders.payment_price) > 0
      )?_c('div',{staticClass:"rn_my_box_11",staticStyle:{"padding":"auto"}},[_c('ValidationObserver',{ref:"obServer"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.apply_receipt.apply(null, arguments)}}},[_c('div',{staticClass:"info_input",staticStyle:{"border-top":"none"}},[_c('div',{staticClass:"box"},[_c('h3',[_vm._v("매출증빙용도")]),_c('div',{staticClass:"radio_sel"},[_c('label',{attrs:{"for":"cuse1"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cuse),expression:"cuse"}],attrs:{"type":"radio","name":"cuse","id":"cuse1","value":"0"},domProps:{"checked":_vm._q(_vm.cuse,"0")},on:{"change":function($event){_vm.cuse="0"}}}),_vm._v("개인 소등공제 ")]),_c('label',{staticStyle:{"margin":"0 0 0 5px"},attrs:{"for":"cuse2"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cuse),expression:"cuse"}],attrs:{"type":"radio","name":"cuse","id":"cuse2","value":"1"},domProps:{"checked":_vm._q(_vm.cuse,"1")},on:{"change":function($event){_vm.cuse="1"}}}),_vm._v("사업자 지출증빙 ")])])])]),(parseInt(_vm.cuse) === 0)?_c('div',{staticClass:"info_input"},[_c('div',{staticClass:"box"},[_c('h3',[_vm._v("휴대폰번호")]),_c('ValidationProvider',{attrs:{"rules":{
                  required: true,
                  cellphone: true,
                },"name":"휴대폰 번호"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('div',{staticClass:"input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cellphoneCk),expression:"cellphoneCk"}],attrs:{"type":"text","name":"cellphone","maxlength":"11"},domProps:{"value":(_vm.cellphoneCk)},on:{"input":function($event){if($event.target.composing){ return; }_vm.cellphoneCk=$event.target.value}}}),(errors[0])?_c('p',{staticClass:"error-txt"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,false,2390149643)})],1)]):_vm._e(),(parseInt(_vm.cuse) === 1)?_c('div',{staticClass:"info_input"},[_c('div',{staticClass:"box"},[_c('h3',[_vm._v("사업자등록번호")]),_c('ValidationProvider',{attrs:{"rules":{
                  required: true,
                  sellingLicense: true,
                },"name":"사업자 번호"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('div',{staticClass:"input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.businessNumberCk),expression:"businessNumberCk"}],attrs:{"type":"text","name":"business","maxlength":"12"},domProps:{"value":(_vm.businessNumberCk)},on:{"input":function($event){if($event.target.composing){ return; }_vm.businessNumberCk=$event.target.value}}}),(errors[0])?_c('p',{staticClass:"error-txt"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,false,1142448596)})],1)]):_vm._e(),_c('div',{staticClass:"info_input"},[_c('div',{staticClass:"box"},[_c('h3',[_vm._v("이메일 주소")]),_c('ValidationProvider',{attrs:{"rules":{
                  required: true,
                  email: true,
                },"name":"이메일"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('div',{staticClass:"input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"text","name":"","maxlength":"64"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),(errors[0])?_c('p',{staticClass:"error-txt"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,false,4157019283)})],1)])])]),_c('p',[_vm._v(_vm._s(_vm.orderData.orders.cash_receipt_possible_txt))])],1):_vm._e(),(
        _vm.orderData.orders.payment === 'virtual' ||
        _vm.orderData.orders.payment === 'escrow_virtual' ||
        _vm.orderData.orders.payment === 'bank'
      )?_c('div',{staticClass:"sect_btn",style:(_vm.orderData.orders.tax_receipt_possible &&
        _vm.orderData.orders.deposit_yn === 'y' &&
        !_vm.orderData.orders.typereceipt
          ? 'margin-top:auto'
          : '')},[(
          _vm.orderData.orders.tax_receipt_possible &&
          _vm.orderData.orders.deposit_yn === 'y' &&
          !_vm.orderData.orders.typereceipt &&
          parseFloat(_vm.orderData.orders.payment_price) > 0
        )?_c('router-link',{staticClass:"black",attrs:{"to":""},nativeOn:{"click":function($event){return _vm.applyCashReceipt.apply(null, arguments)}}},[_vm._v("현금 영수증 신청")]):_vm._e(),(
          _vm.orderData.orders.deposit_yn === 'y' &&
          _vm.orderData.orders.typereceipt &&
          parseFloat(_vm.orderData.orders.payment_price) > 0
        )?_c('router-link',{staticClass:"black",attrs:{"to":""},nativeOn:{"click":function($event){return _vm.viewCashReceipt.apply(null, arguments)}}},[_vm._v("현금 영수증 ")]):_vm._e()],1):_vm._e(),(
        _vm.orderData.orders.payment === 'card' &&
        _vm.orderData.orders.payment_cd !== 'MONEY'
      )?_c('div',{staticClass:"sect_btn"},[_c('router-link',{staticClass:"black",attrs:{"to":""},nativeOn:{"click":function($event){return _vm.viewCardReceipt.apply(null, arguments)}}},[_vm._v("카드 영수증")])],1):_vm._e()],1),_c('div',{staticClass:"rn_info"},[_vm._v(" 주식회사 리뷰앤메이크머니는 통신판매 당사자가 아닌 통신판매중개자입니다. 상품, 상품정보, 거래에 관한 의무와 책임은 통신판매 당사자인 판매자에게 있습니다. ")]),(_vm.isPaymentReceipt)?_c('MyPaymentReceipt',{staticClass:"modals",on:{"open":_vm.open}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }