<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      결제 영수증<router-link to="" @click.native="close"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_38">
      <div class="sect_cont">
        <div class="box">
          <h4>주문정보</h4>
          <div class="cont_box">
            <table summary="">
              <colgroup>
                <col width="80px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <td>거래일시</td>
                  <td>{{ day(orderData.orders.deposit_date) }} 결제</td>
                </tr>
                <tr>
                  <td>주문번호</td>
                  <td>{{ orderData.orders.order_seq }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box">
          <h4>상품정보</h4>
          <div class="cont_box">
            <table summary="">
              <colgroup>
                <col width="80px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <template v-for="(item, index) in itemList">
                  <template>
                    <tr :key="`tr1_${index}`">
                      <td>본상품</td>
                      <td>{{ item.goods_name }}</td>
                    </tr>
                    <tr
                      :key="`tr2_${index}`"
                      v-if="
                        item.title1 ||
                        item.title2 ||
                        item.title3 ||
                        item.title4 ||
                        item.title5
                      "
                    >
                      <td>옵션</td>
                      <td v-if="item.title1">
                        {{ item.title1 }} : {{ item.option1 }}
                      </td>
                      <td v-if="item.title2">
                        {{ item.title2 }} : {{ item.option2 }}
                      </td>
                      <td v-if="item.title3">
                        {{ item.title3 }} : {{ item.option3 }}
                      </td>
                      <td v-if="item.title4">
                        {{ item.title4 }} : {{ item.option4 }}
                      </td>
                      <td v-if="item.title5">
                        {{ item.title5 }} : {{ item.option5 }}
                      </td>
                    </tr>
                    <tr :key="`tr3_${index}`">
                      <td>주문 개수</td>
                      <td>{{ item.ea }}개</td>
                    </tr>
                    <tr class="common" :key="`tr4_${index}`">
                      <td>금액</td>
                      <td>
                        {{
                          won(parseInt(item.sale_price) * parseInt(item.ea))
                        }}원
                      </td>
                    </tr>
                  </template>
                  <template v-if="item.suboptions.length > 0">
                    <template v-for="(sub, idx) in item.suboptions">
                      <template>
                        <tr :key="`sub_tr1_${idx}`">
                          <td>추가상품</td>
                          <td>{{ sub.title }}</td>
                        </tr>
                        <tr :key="`sub_tr2_${idx}`">
                          <td>주문 개수</td>
                          <td>{{ sub.ea }}개</td>
                        </tr>
                        <tr class="common" :key="`sub_tr3_${idx}`">
                          <td>금액</td>
                          <td>
                            {{ won(parseInt(sub.price) * parseInt(sub.ea)) }}원
                          </td>
                        </tr>
                      </template>
                    </template>
                  </template>
                </template>
                <tr>
                  <td>할인</td>
                  <td>
                    {{
                      won(
                        parseInt(orderData.items_tot.event_sale) +
                          parseInt(orderData.items_tot.multi_sale) +
                          parseInt(orderData.items_tot.member_sale) +
                          parseInt(orderData.items_tot.mobile_sale) +
                          parseInt(orderData.items_tot.fblike_sale) +
                          parseInt(orderData.items_tot.coupon_sale) +
                          parseInt(orderData.items_tot.promotion_code_sale) +
                          parseInt(orderData.items_tot.referer_sale) +
                          parseInt(orderData.shipping_tot.coupon_sale) +
                          parseInt(orderData.shipping_tot.code_sale) +
                          parseInt(orderData.orders.enuri)
                      )
                    }}원
                  </td>
                </tr>
                <tr>
                  <td>램 포인트</td>
                  <td>{{ won(parseInt(orderData.orders.emoney)) }}원</td>
                </tr>
                <tr>
                  <td>배송비</td>
                  <td>
                    {{ won(orderData.shipping_tot.total_shipping_cost) }}원
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box">
          <h4>결제액</h4>
          <div class="cont_box">
            <table summary="">
              <colgroup>
                <col width="80px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <td>합계</td>
                  <td>{{ won(orderData.orders.settleprice) }}원</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="rn_my_empty_15"></div>
        <div class="box">
          <div class="cont_box">
            <table summary="">
              <colgroup>
                <col width="80px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <td>회사명</td>
                  <td>주식회사 리뷰앤메이크머니</td>
                </tr>
                <tr class="common">
                  <td>구매자 서명</td>
                  <td>{{ orderData.orders.order_user_name }}</td>
                </tr>
                <tr>
                  <td colspan="2" style="font-weight: normal">
                    결제영수증은 거래내역과 거래액을 확인하는 용도로 사용될 뿐,
                    세무상의 증빙서류로 활용될 수 없습니다.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="rn_info">
      주식회사 리뷰앤메이크머니는 통신판매 당사자가 아닌 통신판매중개자입니다.
      상품, 상품정보, 거래에 관한 의무와 책임은 통신판매 당사자인 판매자에게
      있습니다.
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
import _sortBy from "lodash/sortBy";
export default {
  data() {
    return {
      itemListArray: [],
    };
  },
  computed: {
    ...mapState("order", ["orderData", "result", "msg", "pgInfo"]),
    itemList() {
      let data = {
        addPrice: 0,
        suboptions: [],
      };
      if (this.orderData.items) {
        this.orderData.items.forEach((item) => {
          item.options.forEach((opt) => {
            if (opt.goods_seq) {
              data = {
                addPrice: 0,
                suboptions: [],
              };
              data.out_price = opt.out_price;
              data.sale_price = opt.sale_price;
              data.image = item.image;
              data.goods_name = item.goods_name;
              data.goods_seq = item.goods_seq;
              data.item_option_seq = opt.item_option_seq;
              data.item_seq = opt.item_seq;
              data.title1 = opt.title1;
              data.title2 = opt.title2;
              data.title3 = opt.title3;
              data.title4 = opt.title4;
              data.title5 = opt.title5;
              data.option1 = opt.option1;
              data.option2 = opt.option2;
              data.option3 = opt.option3;
              data.option4 = opt.option4;
              data.option5 = opt.option5;
              data.ea = opt.ea ? opt.ea : 0;
              data.rt_ea = opt.refund_ea ? parseInt(opt.refund_ea) : 0;

              if (opt.suboptions) {
                Object.keys(opt.suboptions).forEach((key) => {
                  const sub = opt.suboptions[key];
                  console.log(sub);
                  let title = sub.title;
                  let suboption = sub.suboption;
                  let price = parseInt(sub.price);
                  data.suboptions.push({
                    title,
                    suboption,
                    ea: sub.ea ? sub.ea : 0,
                    price,
                    item_suboption_seq: sub.item_suboption_seq,
                    rt_ea: sub.refund_ea ? parseInt(sub.refund_ea) : 0,
                  });
                });
                // console.log(data);
                data.suboptions = _sortBy(
                  data.suboptions,
                  "item_suboption_seq"
                );
              }
              this.itemListArray.push(data);
            }
          });
        });
      }
      return this.itemListArray;
    },
  },
  methods: {
    day(str) {
      return this.$moment(str).format("YYYY. MM. DD");
    },
    won(str) {
      return this.$won(str);
    },
    close() {
      this.$emit("open", "close");
    },
  },
};
</script>

<style></style>
