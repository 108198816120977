<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      영수증 조회<router-link to="" @click.native="closeModal()"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_37">
      <h3>영수증 조회/발급</h3>
      <div class="order_info">
        <p>주문번호</p>
        <p>
          <b>{{ orderData.orders.order_seq }}</b>
        </p>
        <router-link to="" @click.native="open">결제 영수증</router-link>
      </div>
      <OrderItemReceipt :orderData="orderData" />
      <div class="del_fee">
        <div class="left">배송비</div>
        <div class="right">
          {{ won(orderData.shipping_tot.total_shipping_cost) }}원
        </div>
      </div>
      <div
        class="rn_my_box_11"
        style="padding: auto"
        v-if="
          orderData.orders.tax_receipt_possible &&
          orderData.orders.deposit_yn === 'y' &&
          !orderData.orders.typereceipt &&
          parseFloat(orderData.orders.payment_price) > 0
        "
      >
        <ValidationObserver ref="obServer">
          <form @submit.prevent="apply_receipt">
            <div class="info_input" style="border-top: none">
              <div class="box">
                <h3>매출증빙용도</h3>
                <div class="radio_sel">
                  <label for="cuse1">
                    <input
                      type="radio"
                      name="cuse"
                      id="cuse1"
                      value="0"
                      v-model="cuse"
                    />개인 소등공제
                  </label>
                  <label for="cuse2" style="margin: 0 0 0 5px">
                    <input
                      type="radio"
                      name="cuse"
                      id="cuse2"
                      value="1"
                      v-model="cuse"
                    />사업자 지출증빙
                  </label>
                </div>
              </div>
            </div>
            <div class="info_input" v-if="parseInt(cuse) === 0">
              <div class="box">
                <h3>휴대폰번호</h3>
                <ValidationProvider
                  :rules="{
                    required: true,
                    cellphone: true,
                  }"
                  v-slot="{ errors }"
                  name="휴대폰 번호"
                >
                  <div class="input">
                    <input
                      type="text"
                      name="cellphone"
                      maxlength="11"
                      v-model="cellphoneCk"
                    />
                    <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="info_input" v-if="parseInt(cuse) === 1">
              <div class="box">
                <h3>사업자등록번호</h3>
                <ValidationProvider
                  :rules="{
                    required: true,
                    sellingLicense: true,
                  }"
                  v-slot="{ errors }"
                  name="사업자 번호"
                >
                  <div class="input">
                    <input
                      type="text"
                      name="business"
                      v-model="businessNumberCk"
                      maxlength="12"
                    />
                    <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="info_input">
              <div class="box">
                <h3>이메일 주소</h3>
                <ValidationProvider
                  :rules="{
                    required: true,
                    email: true,
                  }"
                  v-slot="{ errors }"
                  name="이메일"
                >
                  <div class="input">
                    <input type="text" name="" maxlength="64" v-model="email" />
                    <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </form>
        </ValidationObserver>
        <p>{{ orderData.orders.cash_receipt_possible_txt }}</p>
      </div>
      <div
        class="sect_btn"
        v-if="
          orderData.orders.payment === 'virtual' ||
          orderData.orders.payment === 'escrow_virtual' ||
          orderData.orders.payment === 'bank'
        "
        :style="
          orderData.orders.tax_receipt_possible &&
          orderData.orders.deposit_yn === 'y' &&
          !orderData.orders.typereceipt
            ? 'margin-top:auto'
            : ''
        "
      >
        <router-link
          to=""
          @click.native="applyCashReceipt"
          class="black"
          v-if="
            orderData.orders.tax_receipt_possible &&
            orderData.orders.deposit_yn === 'y' &&
            !orderData.orders.typereceipt &&
            parseFloat(orderData.orders.payment_price) > 0
          "
          >현금 영수증 신청</router-link
        >
        <router-link
          to=""
          @click.native="viewCashReceipt"
          class="black"
          v-if="
            orderData.orders.deposit_yn === 'y' &&
            orderData.orders.typereceipt &&
            parseFloat(orderData.orders.payment_price) > 0
          "
          >현금 영수증
        </router-link>
      </div>
      <div
        class="sect_btn"
        v-if="
          orderData.orders.payment === 'card' &&
          orderData.orders.payment_cd !== 'MONEY'
        "
      >
        <router-link to="" class="black" @click.native="viewCardReceipt"
          >카드 영수증</router-link
        >
      </div>
    </div>

    <div class="rn_info">
      주식회사 리뷰앤메이크머니는 통신판매 당사자가 아닌 통신판매중개자입니다.
      상품, 상품정보, 거래에 관한 의무와 책임은 통신판매 당사자인 판매자에게
      있습니다.
    </div>
    <!---->
    <MyPaymentReceipt v-if="isPaymentReceipt" @open="open" class="modals" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrderItemReceipt from "@/components/mypage/order/OrderItemReceipt";
import MyPaymentReceipt from "@/components/mypage/MyPaymentReceipt";
export default {
  props: {
    orderSeq: {
      type: String,
      default: "",
    },
    pageInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // orderSeq: this.$route.params.order_seq || this.order_seq,
      itemListArray: [],
      orderSeqParam: this.$route.params.order_seq || this.orderSeq,
      cuse: 0,
      cellphone: null,
      email: null,
      businessNumber: null,
      isPaymentReceipt: false,
    };
  },
  created() {
    this.getOrderView();
  },
  computed: {
    ...mapState("order", ["orderData", "result", "msg", "pgInfo"]),
    cellphoneCk: {
      get() {
        return this.cellphone;
      },
      set(cellphone) {
        this.cellphone = cellphone.replace(/[^0-9]/g, "");
      },
    },
    businessNumberCk: {
      get() {
        return this.businessNumber;
      },
      set(businessNumber) {
        this.businessNumber = businessNumber.replace(/[^0-9]/g, "");
      },
    },
  },
  methods: {
    async applyCashReceipt() {
      const validate = await this.$refs.obServer.validate();
      const order_seq = this.orderData.orders.order_seq;
      const order_user_name = this.orderData.orders.order_user_name;
      const settleprice = this.orderData.orders.settleprice;
      const order_step = this.orderData.orders.step;
      const cuse = parseInt(this.cuse);
      const email = this.email;
      const cellphone = this.cellphone;
      const business = this.businessNumber;

      // console.log(
      //   order_seq,
      //   order_user_name,
      //   settleprice,
      //   order_step,
      //   cuse,
      //   email,
      //   cellphone,
      //   business
      // );
      // return false;
      if (validate) {
        this.$confirm(
          "현금영수증 발급 신청이 완료되면 3일 후 홈텍스에서 조회가 가능합니다."
        )
          .then(async () => {
            await this.$store.dispatch("order/applyCashReceipt", {
              validate,
              order_seq,
              order_user_name,
              settleprice,
              order_step,
              cuse,
              email,
              cellphone,
              business,
            });
            this.$toast.default(this.msg);
          })
          .catch(() => {
            return false;
          });
      }
    },
    async viewCashReceipt() {
      const order_seq = this.orderData.orders.order_seq;
      await this.$store.dispatch("order/viewCashReceipt", {
        order_seq,
      });
      const shopid = this.pgInfo.shopid;
      const tax_bank = this.pgInfo.tax_bank;
      const cst_platform = this.pgInfo.cst_platform;
      if (this.pgInfo.result) {
        showCashReceipts(shopid, order_seq, "01", tax_bank, cst_platform);
      }
    },
    async viewCardReceipt() {
      const order_seq = this.orderData.orders.order_seq;
      await this.$store.dispatch("order/viewCashReceipt", {
        order_seq,
      });
      const tno = this.orderData.orders.pg_transaction_number;
      const authdata = this.pgInfo.authdata;
      const shopid = this.pgInfo.shopid;
      if (this.pgInfo.result) {
        // console.log(shopid, tno, authdata);
        showReceiptByTID(shopid, tno, authdata);
      }
    },
    won(str) {
      return this.$won(str);
    },
    async getOrderView() {
      await this.$store.dispatch("order/getOrderView", {
        order_seq: this.orderSeqParam,
      });
      if (!this.result) {
        this.$toast.default(this.msg);
        this.$router.push("/");
      }
    },
    closeModal(pageInfo = null) {
      if (this.$route.path.includes("/mypage/my_order_receipt")) {
        this.$router.push("/mypage/payment_list").catch(() => {});
      }
      console.log(pageInfo);
      this.$emit("closeModal", "receipt", pageInfo);
    },
    open() {
      if (this.isPaymentReceipt) {
        document.querySelector("html").style.overflow = "";
        this.isPaymentReceipt = false;
      } else {
        document.querySelector("html").style.overflow = "hidden";
        this.isPaymentReceipt = true;
      }
    },
  },
  components: {
    OrderItemReceipt,
    MyPaymentReceipt,
  },
  beforeDestroy() {
    document.querySelector("html").style.overflow = "";
  },
};
</script>

<style lang="scss">
.rn_my_box_11 {
  p {
    padding-bottom: 15px;
  }
}
.modals {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #fff;
  overflow: scroll;
  height: calc(100% - 90.33px);
}
</style>
